<template>
  <div>
    <el-table
      class="flex_1 width-100"
      :data="goods"
      height="400px"
      border
      :show-overflow-tooltip="true"
    >
      <el-table-column
        type="index"
        label="序号"
        width="50"
      />
      <el-table-column
        v-for="item in TABLECOLUMN"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :type="item.type"
        :width="item.width"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div v-if="item.prop === 'bookingNumBig'">
            <el-input-number
              v-model="scope.row.bookingNumBig"
              :step="1"
              :precision="0"
              step-strictly
              class="width-80-px"
              size="mini"
              :min="0"
              placeholder="请输入"
              :controls="false"
              @change="
                currentValue => {
                  handleBookingNumCount(currentValue, scope.row, scope.$index);
                }
              "
            />
            {{ scope.row.bigUnit || scope.row.goods.bigUnit }}
          </div>
          <div v-else-if="item.prop === 'bookingNumSmall'">
            <el-input-number
              v-model="scope.row.bookingNumSmall"
              step-strictly
              :min="0"
              class="width-80-px"
              size="mini"
              :controls="false"
              placeholder="请输入"
              :step="0.001"
              :precision="3"
              @change="
                currentValue => {
                  handleBookingNumCountSmall(currentValue, scope.row, scope.$index);
                }
              "
            />
            {{ scope.row.smallUnit|| scope.row.goods.smallUnit }}
          </div>
          <div v-else-if="item.prop === 'designatedGoodsQuality'">
            <el-select
              v-model="scope.row.designatedGoodsQuality"
              clearable
              placeholder="请选择"
              @change="(val)=>goodsQualityChange(val,scope.row)"
              @focus="(e)=>goodsQualityFocus(e,scope.row.designatedGoodsQuality)"
            >
              <el-option
                v-for="it in GOODS_QUALITY"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              />
            </el-select>
          </div>
          <div v-else-if="item.prop === 'ext.remark'">
            <el-input
              v-model="scope.row.ext.remark"
              clearable
              type="textarea"
              placeholder="请输入"
              maxlength="100"
            />
          </div>
          <span v-else-if="item.formatter">{{ item.formatter(scope.row[item.prop]) }}</span>
          <span v-else>{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
      <!-- 操作栏 -->
      <nh-table-action-column width="150">
        <template #default="scope">
          <nh-button type="text" @click="handleStoreSet(scope.row, scope.$index)">
            编辑
          </nh-button>
          <nh-button
            type="text"
            class="text-color"
            @click="handleRemove(scope.row, scope.$index)"
          >
            删除
          </nh-button>
        </template>
      </nh-table-action-column>
      <!-- / 操作栏 -->
    </el-table>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import utils from '@/utils/unitNumber';
import TABLECOLUMN from './fileds';
import mixin from '../mixin';

export default {
  name: 'AddGoods',
  components: {},
  mixins: [loadingMixin, mixin],
  props: {
    goods: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['goodsEdit', 'removeGoods', 'updateGoodsBookingNum', 'updateGoodsBookingNum'],
  data() {
    return {
      TABLECOLUMN,
      tableData: [],
      oldSelect: '',
    };
  },
  methods: {
    async handleRemove(rowData, rowIndex) {
      await this.$confirm('你确定要删除该货品吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
      });
      this.$emit('removeGoods', rowData, rowIndex);
    },
    handleStoreSet(rowData, rowIndex) {
      this.$emit('goodsEdit', rowData, rowIndex);
    },
    handleBookingNumCountSmall(currentValue, rowData, rowIndex) {
      const result = utils.getTotal({
        smallUnitNumber: currentValue,
        bigUnitNumber: rowData.bookingNumBig,
        conversionRate: rowData.conversionRate || rowData.goods.conversionRate,
      });
      this.$emit('updateGoodsBookingNum', {
        field: 'bookingNum',
        num: result,
        id: rowData.id,
        rowIndex,
        rowData: {
          ...rowData,
          bookingNumSmall: currentValue,
        },
      });
    },
    handleBookingNumCount(currentValue, rowData, rowIndex) {
      const result = utils.getTotal({
        smallUnitNumber: rowData.bookingNumSmall,
        bigUnitNumber: currentValue,
        conversionRate: rowData.conversionRate || rowData.goods.conversionRate,
      });
      this.$emit('updateGoodsBookingNum', {
        field: 'bookingNum',
        num: result,
        id: rowData.id,
        rowIndex,
        rowData: {
          ...rowData,
          bookingNumBig: currentValue,
        },
      });
    },
    goodsQualityFocus(e, val) {
      this.oldSelect = val;
    },
    goodsQualityChange(val, row) {
      const newRow = row;
      const elseGoods = this.goods.filter((item) => item !== row);
      // 批属性是否相同
      const listIndex = elseGoods.findIndex((list) => {
        let flag = false;
        if (list.batchs?.length === row.batchs?.length) {
          flag = list.batchs.every((item) => row.batchs.some(
            (batch) => ((batch.batchNumber === item.batchNumber
            || (!batch.batchNumber && !item.batchNumber))
            && (batch.batch === item.batch || (!batch.batch
             && !item.batch))),
          ));
        }
        return flag && list.shipperGoodsCode === row.shipperGoodsCode
        // 货品质量相等
         && (list.designatedGoodsQuality === row.designatedGoodsQuality
         // 货品质量为空
         || (!list.designatedGoodsQuality && !row.designatedGoodsQuality));
      });
      if (listIndex >= 0) {
        this.$message({ type: 'warning', message: '不可设置重复项，请确认后重试！' });
        newRow.designatedGoodsQuality = this.oldSelect;
      } else {
        this.oldSelect = val;
      }
    },
  },
};
</script>
<style scoped>
.width-100 {
  width: 100%;
}

.text-color {
  color: #f56c6c;
}

.width-80-px {
  width: 80px;
}
</style>
