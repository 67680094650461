export default [{
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  width: 180,
}, {
  label: '货品名称',
  prop: 'goodsName',
  width: 180,
}, {
  label: '温层',
  prop: 'temperatureLayerName',
}, {
  label: '规格',
  prop: 'goodsSpecifications',
}, {
  label: '默认单位',
  prop: 'defaultUnit',
}, {
  label: '预约数量(整单位)',
  prop: 'bookingNumBig',
  width: 150,
}, {
  label: '预约数量(零单位)',
  prop: 'bookingNumSmall',
  width: 150,
}, {
  label: '指定批属性',
  prop: 'batchs',
  width: 180,
  formatter: (batchs) => (batchs ? batchs.map((item) => (item.attrName ? `${item.attrName}:${item.batchNumber ? item.batchNumber : ''};` : '')).join('') : ''),
},
{
  label: '指定货品质量',
  prop: 'designatedGoodsQuality',
  width: 180,
},
{
  label: '备注',
  prop: 'ext.remark',
  width: 180,
},
];
