import { FormType } from '@/constant/form';

export const FORM_FIELDS = [{
  label: '货主货品编码',
  prop: 'goods',
  component: FormType.SELECT,
  colAttrs: {
    span: 11,
  },
  formItemAttrs: {
    rules: [{ required: true, message: '请输入货品编码', trigger: 'blur' }],
  },
}, {
  label: '货品名称',
  prop: 'goodsName',
  component: FormType.INPUT,
  componentAttrs: {
    disabled: 'true',
  },
  colAttrs: {
    span: 11,
  },
}, {
  label: '货品编码',
  prop: 'goodsCode',
  component: FormType.INPUT,
  componentAttrs: {
    disabled: 'true',
  },
  colAttrs: {
    span: 11,
  },
}, {
  label: '条码',
  prop: 'internationalCode',
  component: FormType.INPUT,
  componentAttrs: {
    disabled: 'true',
  },
  colAttrs: {
    span: 11,
  },
}, {
  label: '规格',
  prop: 'goodsSpecifications',
  component: FormType.INPUT,
  componentAttrs: {
    disabled: 'true',
  },
  colAttrs: {
    span: 11,
  },
}, {
  label: '件重',
  prop: 'weight',
  component: FormType.INPUT,
  componentAttrs: {
    disabled: 'true',
  },
  colAttrs: {
    span: 11,
  },
}, {
  label: '保质期',
  prop: 'shelfLife',
  component: FormType.INPUT,
  componentAttrs: {
    disabled: 'true',
  },
  colAttrs: {
    span: 11,
  },
}, {
  label: '温层',
  prop: 'temperatureLayerName',
  component: FormType.INPUT,
  componentAttrs: {
    disabled: 'true',
  },
  colAttrs: {
    span: 11,
  },
}, {
  label: '数量',
  prop: 'bookingNumBig',
  colAttrs: {
    span: 11,
  },
}, {
  label: '指定批属性',
  prop: 'batchs',
  colAttrs: {
    span: 22,
  },
}];
export const FORM_FIELDS_VALUE = [{
  label: '货主货品编码',
  prop: 'goodsCode',
}, {
  label: '货品名称',
  prop: 'goodsName',
}, {
  label: '货品编码',
  prop: 'temperatureLayerCode',
  // ENUM: TEMPERATURE_LAYER_CODE_ENUM,
}, {
  label: '条码',
  prop: 'goodsSpecifications',
}, {
  label: '规格',
  prop: 'defaultUnit',
}];
