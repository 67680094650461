<template>
  <div class="body">
    <div class="basic-info">
      <p class="margin-bottom16">
        <b class="margin-left">新增入库单</b>
      </p>
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="120px"
        :rules="rules"
        @submit.prevent
      >
        <el-row :gutter="21">
          <el-col :span="8">
            <el-form-item
              label="所属货主"
              prop="customer"
            >
              <p v-if="isEdit">
                {{ form.customerName || form.shipperName }}
              </p>
              <nh-shipper-selector
                v-else
                v-model:shipper="form.customer"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="入库单类型"
              prop="storeTypeCode"
            >
              <p v-if="isEdit">
                {{ RECEIPT_ADD_TYPE_ENUM[form.storeTypeCode] }}
              </p>
              <el-select
                v-else
                v-model="form.storeTypeCode"
                placeholder="请选择入库单类型"
                class="width-100"
                value-key="value"
              >
                <el-option
                  v-for="item in RECEIPT_ADD_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="预约送货时间"
              prop="storeTime"
            >
              <el-date-picker
                v-model="form.storeTime"
                :disabled-date="bookingTimeOptions"
                :default-value="defaultValue"
                type="datetime"
                width="100%"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货主订单号" prop="freshScmOrderNo">
              <el-input v-model="form.freshScmOrderNo" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" prop="supplierName">
              <el-input v-model="form.supplierName" :maxlength="32" />
            </el-form-item>
          </el-col>
          <el-col :span="8" />
          <el-col :span="8">
            <el-form-item label="是否需要装卸" prop="handling">
              <el-select
                v-model="form.handling"
                placeholder="请选择"
                @change="handlingChange"
              >
                <el-option
                  v-for="item in URGENT"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              v-if="form.handling"
              label="是否外包装卸"
              prop="outsourceHandling"
            >
              <el-select
                v-model="form.outsourceHandling"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in URGENT"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" />
          <el-col :span="8">
            <el-form-item label="备注" prop="note">
              <el-input
                v-model="form.note"
                :maxlength="500"
                show-word-limit
                type="textarea"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="" prop="files">
              <nh-upload-attachment
                v-model:upload-file="fileData"
                :uploads-style="{
                  padding: '12px 0',
                  backgroundColor: '#f6f8fa',
                  borderRadius: '4px',
                }"
                @onRemove="handleRemove"
                @onAllRemove="handleAllRemove"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <p class="margin-bottom">
      <b class="margin-right">货品信息</b>
      <nh-button type="primary" @click="handleShowGoodDialog">
        添加货品
      </nh-button>
    </p>
    <AddGoods
      ref="addGoods"
      :title="isEdit ? '编辑': '新增'"
      :shipper-id="shipperId"
      @goodsAdded="handleAddGoods"
      @goodsUpdate="handleUpdateGood"
    />
    <AddedGoods
      :goods="goods"
      @removeGoods="handleRemoveGoods"
      @updateGoodsBookingNum="handleUpdateGoodBookingNum"
      @goodsEdit="handleGoodsEdit"
    />
    <el-row>
      <el-col :span="24" class="footer-btn">
        <nh-button
          type="primary"
          :loading="loading.inboundOrderSave"
          @click="handleSaveInboundOrder"
        >
          保存
        </nh-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';
import loadingMixin from '@thales/loading';
import utils from '@/utils/unitNumber';
import mixin from './mixin';
import {
  RECEIPT_ADD_TYPE,
  PICKUP_TYPE,
  TEMPERATURE_LAYER_TO_NEW_ENUM,
  RECEIPT_ADD_TYPE_ENUM,
  RECEIPT_TYPE_TO_RECEIPT_ADD_TYPE,
  TEMPERATURE_LAYER_CODE_NEW_ENUM,
  URGENT,
  URGENT_ENUM,
} from './constant';
import {
  inboundOrderSave,
  inboundOrderDetail,
} from './api';
import AddGoods from './addGoods/index.vue';
import AddedGoods from './addedGoods/index.vue';
import { timeFormat } from './utils';
import { ADD_FORM_FIELDS } from './fileds';

export default {
  name: 'InboundOrderCreate',
  components: { AddGoods, AddedGoods },
  mixins: [loadingMixin, mixin],
  data() {
    return {
      ADD_FORM_FIELDS,
      RECEIPT_ADD_TYPE,
      RECEIPT_ADD_TYPE_ENUM,
      PICKUP_TYPE,
      URGENT,
      URGENT_ENUM,
      shipperId: '',
      dialogVisible: false,
      goods: [],
      loading: {
        inboundOrderSave: false,
      },
      editRowIndex: 0,
      form: {
        customer: null,
        shipperCode: '',
        shipperName: undefined,
        storeTypeCode: undefined,
        freshScmOrderNo: undefined,
        storeTime: moment(),
        note: undefined,
        files: [],
        handling: undefined,
        outsourceHandling: undefined,
      },
      rules: {
        customer: [
          { required: true, message: '请输入所属货主', trigger: 'blur' },
        ],
        storeTypeCode: [
          { required: true, message: '请选择入库单类型', trigger: 'change' },
        ],
        freshScmOrderNo: [
          { required: false, message: '请输入货主订单号', trigger: 'blur' },
        ],
        storeTime: [
          { required: true, message: '请选择预约送货时间', trigger: 'change' },
        ],
        note: [
          { required: false, message: '请输入备注', trigger: 'blur' },
        ],
        files: [
          { required: false, message: '请选择附件', trigger: 'change' },
        ],
        handling: [{ required: true, message: '请选择', trigger: 'blur' }],
        outsourceHandling: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      bookingTimeOptions: (time) => time < moment().subtract(1, 'day').valueOf(),
      fileData: [],
      isEdit: false,
      defaultValue: moment(),
    };
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.userInfo || {},
      account: ({ user }) => user.accountInfo || {},
    }),
  },
  watch: {
    'form.customer': {
      handler(newValue) {
        this.form.shipperCode = newValue.shipperCode || newValue.customerCode;
        this.form.shipperName = newValue.shipperName || newValue.customerName;
        this.form.shipperId = newValue.shipperId;
        this.shipperId = newValue.shipperId;
        this.form.handling = newValue.baseRuleExt?.receiptOrderHandling;
        this.form.outsourceHandling = newValue.baseRuleExt?.receiptOrderHandling ? true : undefined;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.loadInboundOrderDetail();
    }
    // 预约入库单进入
    if (this.$route.query.reserveId) {
      this.loadInboundOrderDetail();
    }
  },
  methods: {
    handleShowGoodDialog() {
      if (this.shipperId) {
        this.$refs.addGoods.showDialog();
      } else {
        this.$message({ type: 'warning', message: '请选择货主之后再添加货品' });
      }
    },
    handlingChange(val) {
      if (val === URGENT[0].value) {
        this.form.outsourceHandling = URGENT[0].value;
      } else {
        this.form.outsourceHandling = undefined;
      }
    },
    handleAddGoods(selectGoods) {
      let goods = [];
      // 获取同货品同批次 list 下标
      const listIndex = this.findRepeated(selectGoods, this.goods);
      if (listIndex >= 0) {
        goods = this.goods.map((list, index) => {
          if (index === listIndex) {
            return {
              ...list,
              bookingNumBig: Number(selectGoods.bookingNumBig) + list.bookingNumBig,
              bookingNumSmall: Number(selectGoods.bookingNumSmall) + list.bookingNumSmall,
            };
          }
          return list;
        });
      } else {
        goods = [
          ...this.goods,
          {
            ...selectGoods,
            ...selectGoods.selectGoods,
            temperatureLayer: TEMPERATURE_LAYER_TO_NEW_ENUM[selectGoods.temperatureLayerCode - 0],
            bookingNumBig: selectGoods.bookingNumBig - 0,
            bookingNumSmall: selectGoods.bookingNumSmall - 0,
            ext: {
              remark: '',
            },
          }];
      }
      this.goods = goods;
    },
    handleUpdateGood(selectGoods) {
      let goods = [];
      const oldGoods = this.goods.filter((item, index) => this.editRowIndex !== index);
      const listIndex = this.findRepeated(selectGoods, oldGoods);
      if (listIndex >= 0) {
        goods = oldGoods.map((list, index) => {
          if (index === listIndex) {
            return {
              ...list,
              bookingNumBig: Number(selectGoods.bookingNumBig) + list.bookingNumBig,
              bookingNumSmall: Number(selectGoods.bookingNumSmall) + list.bookingNumSmall,
            };
          }
          return list;
        });
      } else {
        goods = this.goods.map((item, index) => {
          if (this.editRowIndex === index) {
            return { ...selectGoods, ...selectGoods.selectGoods };
          }
          return item;
        });
      }
      this.goods = goods;
    },
    findRepeated(selectGoods, goods) {
      // 同货品同批次属性
      const listIndex = goods.findIndex((list) => {
        // 批属性是否相同
        let flag = false;
        if (list.batchs?.length === selectGoods.batchs?.length) {
          flag = list.batchs.every((item) => selectGoods.batchs.some(
            (batch) => ((batch.batchNumber === item.batchNumber
            || (!batch.batchNumber && !item.batchNumber))
            && (batch.batch === item.batch || (!batch.batch
             && !item.batch))),
          ));
        }
        return flag && list.shipperGoodsCode === selectGoods.shipperGoodsCode
        // 货品质量相等
         && (list.designatedGoodsQuality === selectGoods.designatedGoodsQuality
         // 货品质量为空
         || (!list.designatedGoodsQuality && !selectGoods.designatedGoodsQuality));
      });
      return listIndex;
    },
    async handleSaveInboundOrder() {
      await this.$refs.ruleForm.validate();
      if (this.goods.every((item) => item.bookingNum > 0)) {
        if (moment().isSameOrBefore(this.form.storeTime, 'day')) {
          this.inboundOrderSave({
            storeManage: {
              supplierName: this.form.supplierName,
              freshScmOrderNo: this.form.freshScmOrderNo,

              storeTypeCode: this.form.storeTypeCode,
              note: this.form.note,
              storeTime: moment(Number(this.form.storeTime)).format('YYYY-MM-DD HH:mm'),
              shipperName: this.form.shipperName,
              shipperCode: this.form.shipperCode,
              handling: this.form.handling,
              outsourceHandling: this.form.outsourceHandling,
              ext: {
                attachment: this.fileData.map((item) => item.filePath).join(','),
                // TODO 文件名需要自写方法获取
                attachmentName: this.fileData.map((item) => item.fileName).join(','),
              },
              updateSource: this.isEdit ? 'WMS' : undefined,
              id: this.form.id,
              customerOrderNo: this.form.externalOrderNo,
            },

            storeGoodsDetailList: this.goods.map((item) => {
              const batchAttributeId = (item.qualityControlExt && item.qualityControlExt.batchAttributeId) ? item.qualityControlExt.batchAttributeId : '';
              return {
                skuCode: item.goodsCode,
                batchNo: '',
                defaultUnitPre: item.bookingNum,
                designatedGoodsQuality: item.designatedGoodsQuality,
                ext: {
                  batchAttr: item.batchs.map((batchItem) => ({
                    attrValue: batchItem.batchNumber,
                    attrCode: batchItem.batch,
                    attrName: batchItem.attrName,
                    attrId: this.isEdit ? batchItem.attrId : batchAttributeId,
                  })),
                  remark: item.ext.remark,
                },
              };
            }),
          });
        } else {
          this.$message({ type: 'warning', message: '请选择当前时间之后的预约提货时间' });
        }
      } else {
        this.$message({ type: 'warning', message: '已添加货品数量不能小于0' });
      }
    },
    handleRemove({ index }) {
      // 删除附件
      this.fileData = this.fileData.filter((item, i) => i !== index);
    },
    handleAllRemove() {
      this.fileData = [];
    },
    handleRemoveGoods(rowData, rowIndex) {
      // 用filter 代替删除动作
      this.goods = this.goods.filter((item, index) => index !== rowIndex);
    },
    handleGoodsEdit(rowData, rowIndex) {
      this.editRowIndex = rowIndex;
      this.$refs.addGoods.showDialog(rowData);
    },
    handleUpdateGoodBookingNum(updateRow) {
      // 利用map 实现更新列表内某项字段
      this.goods = this.goods.map((item, index) => {
        // 当货品ID，已添加货品序号，才确认为同一条数据
        if (updateRow.id === item.id && index === updateRow.rowIndex) {
          return {
            ...item,
            ...updateRow.rowData,
            [updateRow.field]: updateRow.num,
          };
        }
        return item;
      });
    },
    async inboundOrderSave(data) {
      const result = await inboundOrderSave([{
        ...data,
      }]);
      if (result) {
        this.$message({
          message: '保存成功',
          type: 'success',
        });
        this.$router.go(-1);
      }
    },
    async loadInboundOrderDetail() {
      this.isEdit = true;
      const result = await inboundOrderDetail({
        receiptOrderId: this.$route.query.id,
        needAcceptance: false,
        needUpperShelf: false,
      });
      this.StoreData = result;
      this.form = {
        // supplierName: result.supplierName,
        externalOrderNo: result.externalOrderNo,
        freshScmOrderNo: result.customerOrderNo,
        storeTypeCode: RECEIPT_TYPE_TO_RECEIPT_ADD_TYPE[result.receiptType],
        note: result.remark,
        storeTime: result.bookingTime || this.defaultValue,
        shipperName: result.shipperName,
        shipperCode: result.shipperCode,
        supplierName: result.supplierName,
        customer: {
          shipperCode: result.shipperCode,
          shipperName: result.shipperName,
          shipperId: result.shipperId,
        },
      };
      this.$nextTick(() => {
        this.form.handling = result.handling;
        this.form.outsourceHandling = result.outsourceHandling;
      });
      this.goods = result.goods.map((item) => {
        const total = utils.calculate({
          smallUnitNumber: item.bookingNum,
          conversionRate: item.conversionRate,
        });
        return ({
          ...item,
          batchs: item.batchAttr.map((batchItem) => ({
            ...batchItem,
            batch: batchItem.attrCode,
            attrName: batchItem.attrName,
            batchAttributeId: batchItem.attrId,
            batchNumber: batchItem.attrValue,
          })),
          temperatureLayerName: TEMPERATURE_LAYER_CODE_NEW_ENUM[item.temperatureLayer],
          goodsSpecifications: item.spec,
          bookingNumBig: total.bigUnitNumber,
          bookingNumSmall: total.smallUnitNumber,
          designatedGoodsQuality: item.quality,
        });
      });
      this.fileData = result.files.map((item) => ({
        ...item, url: item.filePath, fileName: item.fileName, fileTime: timeFormat(item.updateTime),
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  padding-bottom: 24px;
}

.basic-info {
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(0, 25, 55, 0.1);
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 16px 24px;
}

.margin-left {
  margin-left: 4px;
}

.margin-bottom {
  margin-bottom: 24px;
}

.margin-bottom16 {
  margin-bottom: 16px;
}

.margin-right {
  margin-right: 24px;
}

.footer-btn {
  padding-top: 12px;
  padding-right: 12px;
  text-align: right;
}
</style>
