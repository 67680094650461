<template>
  <nh-dialog
    v-model="visible"
    title="添加货品"
    :before-close="handleClose"
    :track="{
      name_zh: `入库管理/入库单管理/${title}页/弹窗-添加货品`,
      trackName: $route.path
    }"
  >
    <PlForm
      v-model="formData"
      mode="normal"
      :fields="FORM_FIELDS"
      :show-operation="false"
      :collapse="false"
      :show-collapse-btn="false"
      :form-props="{
        labelWidth:'150px'
      }"
    >
      <template #goods>
        <nh-remote-select
          v-model="formData.goods"
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="handleSearchCustomer"
          :loading="loading.loadGoodsByWms"
          class="width-100"
          value-key="shipperGoodsCode"
          :disabled="isEdit"
        >
          <el-option
            v-for="item in goods"
            :key="item.shipperGoodsCode"
            :label="item.shipperGoodsCode"
            :value="item"
          >
            <div class="goods-attr-box">
              <span class="goods-attr">{{ item.shipperGoodsCode }}</span>
              <span class="goods-attr">{{ item.goodsName }}</span>
              <span class="goods-attr">{{ item.goodsSpecifications }}</span>
              <span class="goods-attr">{{ item.defaultUnit }}</span>
            </div>
          </el-option>
        </nh-remote-select>
      </template>
      <template #bookingNumBig>
        <el-row>
          <el-col
            v-if="formData.goods.smallUnit !== formData.goods.bigUnit"
            class="number-box"
            :span="12"
          >
            <el-input-number
              v-model="formData.bookingNumBig"
              step-strictly
              :min="0"
              :controls="false"
              placeholder="请输入"
              :step="1"
              :precision="0"
              class="inb-add-number"
              @blur="handleNumberBigChange"
            /> <div class="number-unit">
              {{ formData.goods.bigUnit }}
            </div>
          </el-col>
          <el-col class="number-box" :span="12">
            <el-input-number
              v-model="formData.bookingNumSmall"
              step-strictly
              :min="0"
              :controls="false"
              placeholder="请输入"
              :step="0.001"
              :precision="3"
              class="inb-add-number"
              @blur="handleNumberSmallChange"
            /> <div class="number-unit">
              {{ formData.goods.smallUnit }}
            </div>
          </el-col>
        </el-row>
      </template>
      <template #batchs>
        <el-row
          v-for="(batchItem,index) in formData.batchs"
          :key="`${batchItem.fieldCode}-${index}`"
          class="margin-bottom"
        >
          <el-select
            v-model="batchItem.batchItemInside"
            placeholder="下拉选择当前货品关联的批属性"
            class="margin-right"
            :disabled="isHaveAttrBath"
            value-key="fieldCode"
            @change="handleChangeBatch(index)"
          >
            <el-option
              v-for="(goodBathItem) in goodBatchs"
              :key="goodBathItem.fieldCode"
              :label="goodBathItem.fieldName"
              :value="goodBathItem"
              :disabled="goodBathItem.selected"
            >
              {{ goodBathItem.fieldName }}
            </el-option>
          </el-select>
          <div
            v-if="batchItem.batchItemInside
              &&batchItem.batchItemInside.component === 'date'"
            class="margin-right"
          >
            <el-date-picker
              v-model="batchItem.batchValue"
              type="date"
              :disabled="isHaveAttrBath"
              :disabled-date="bathAttributeTimeOptions(batchItem.batchItemInside)"
              :value-format="YMD"
            />
          </div>

          <el-input
            v-else
            v-model="batchItem.batchValue"
            :disabled="isHaveAttrBath"
            class="margin-right"
          />
          <nh-button
            v-if="isAddAttrBatch"
            @click="addBatch"
          >
            <i class="el-icon-plus" />
          </nh-button>
          <nh-button
            @click="cutBatch(batchItem,index)"
          >
            <i class="el-icon-minus" />
          </nh-button>
        </el-row>
      </template>
    </PlForm>

    <template #footer>
      <div class="dialog-footer">
        <nh-button
          type="primary"
          :loading="loading.headleSubmitForm"
          @click="headleConfirm"
        >
          {{ isEdit?'保存货品':'添加货品' }}
        </nh-button>
        <nh-button
          @click="handleClose"
        >
          取消
        </nh-button>
      </div>
    </template>
  </nh-dialog>
  <!-- </div> -->
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from 'moment';
import utils from '@/utils/unitNumber';
import { YMD } from '@/constant/timeFormat';
import { loadGoodsByWms, ruleBatchAttribute } from '../api';
import { FORM_FIELDS } from './fileds';

const PRODUCTION_DATE = 'PRODUCTION_DATE';

export default {
  name: 'AddGood',
  mixins: [loadingMixin],
  props: {
    shipperId: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['goodsUpdate', 'goodsAdded'],

  data() {
    return {
      FORM_FIELDS,
      YMD,
      goods: [],
      formData: {
        batchs: [{ batchValue: undefined }],
        bookingNumBig: 0,
        bookingNumSmall: 0,
        goodsName: undefined,
        goodsCode: undefined,
        internationalCode: undefined,
        goodsSpecifications: undefined,
        weight: undefined,
        shelfLife: undefined,
        temperatureLayerName: undefined,
        goods: {},
      },
      loading: {
        loadGoodsByWms: false,
      },
      visible: false,
      goodBatchs: [{ selected: false }],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      isEdit: false,
      bookingTimeOptions: (time) => time > moment().valueOf(),
    };
  },
  computed: {
    // 是否可以新增 批属性
    isAddAttrBatch() {
      return this.goodBatchs.some((item) => !item.selected);
    },
    // 当前货品 是否具有批属性
    isHaveAttrBath() {
      const bool = !(this.formData.goods
      && this.formData.goods.qualityControlExt
      && this.formData.goods.qualityControlExt.batchAttributeId);
      return bool;
    },
  },
  watch: {
    'formData.goods': {
      handler(newValue) {
        if (newValue) {
          this.formData.goodsName = newValue.goodsName;
          this.formData.goodsCode = newValue.goodsCode;
          this.formData.shipperGoodsCode = newValue.shipperGoodsCode;
          this.formData.defaultUnit = newValue.defaultUnit;
          this.formData.temperatureLayerName = newValue.temperatureLayerName;
          this.formData.goodsSpecifications = newValue.goodsSpecifications;
          this.formData.shelfLife = newValue.shelfLife;
          this.formData.weight = newValue.weight;
          this.formData.bookingNumBig = newValue.bookingNumBig || this.formData.bookingNumBig;
          this.formData.bookingNumSmall = newValue.bookingNumSmall || this.formData.bookingNumSmall;
          this.formData.internationalCode = newValue.internationalCode;
          this.loadRuleBatchAttribute(newValue);
        }
      },
      deep: true,
    },
    'formData.bookingNumBig': {
      handler() {
        const result = utils.getTotal({
          smallUnitNumber: this.formData.bookingNumSmall,
          bigUnitNumber: this.formData.bookingNumBig,
          conversionRate: this.formData.conversionRate || this.formData.goods.conversionRate,
        });
        this.formData.bookingNum = result;
      },
      deep: true,
    },
    'formData.bookingNumSmall': {
      handler() {
        const result = utils.getTotal({
          smallUnitNumber: this.formData.bookingNumSmall,
          bigUnitNumber: this.formData.bookingNumBig,
          conversionRate: this.formData.conversionRate || this.formData.goods.conversionRate,
        });
        this.formData.bookingNum = result;
      },
      deep: true,
    },
    'formData.batchs': {
      handler(newValue) {
        const selectedBatchAttributeCodes = newValue
          .map((item) => item.batchItemInside && item.batchItemInside.fieldCode);
        this.goodBatchs = this.goodBatchs.map((item) => ({
          ...item,
          selected: selectedBatchAttributeCodes.includes(item.fieldCode),
        }));
      },
      deep: true,
    },
  },
  methods: {
    bathAttributeTimeOptions(item) {
      if (item && item.fieldCode === PRODUCTION_DATE) {
        return this.bookingTimeOptions;
      }
      return null;
    },
    async showDialog(data) {
      this.visible = true;
      if (data) {
        const { goods, ...info } = data;
        this.formData = info;
        this.isEdit = true;
        this.formData.goods = goods || info;
        this.loadGoodsByWms(data.goodsCode);
      } else {
        this.loadGoodsByWms();
      }
    },
    headleConfirm() {
      // 深拷贝 防止 添加同一个 商品后，会导致两个商品的数量会同时修改问题
      const newFormData = JSON.parse(JSON.stringify(this.formData));
      newFormData.batchs = newFormData.batchs.map((item) => {
        let batchNumber = item.batchValue;
        if (item.batchItemInside && item.batchItemInside.component === 'date' && item.batchValue) {
          batchNumber = moment(item.batchValue).format('YYYY-MM-DD');
        }
        return {
          ...item,
          batch: item.batchItemInside && item.batchItemInside.fieldCode,
          attrName: item.batchItemInside && item.batchItemInside.fieldName,
          batchNumber,
        };
      });
      if (!newFormData.goodsCode) {
        this.$message({ message: '请选择货品', type: 'warning' });
      } else if (newFormData.bookingNum - 0 === 0) {
        this.$message({ message: '请输入正确的货品数量', type: 'warning' });
      } else {
        if (this.isEdit) {
          this.$emit('goodsUpdate', newFormData);
        } else {
          this.$emit('goodsAdded', newFormData);
        }
        this.handleClose();
      }
    },
    handleReset() {
      this.formData = {
        batchs: [{}],
        bookingNumBig: 0,
        bookingNumSmall: 0,
        goodsName: undefined,
        goodsCode: undefined,
        internationalCode: undefined,
        goodsSpecifications: undefined,
        weight: undefined,
        shelfLife: undefined,
        temperatureLayerName: undefined,
        goods: {},
      };
      this.$forceUpdate();
    },
    handleClose() {
      // nh-dialog-form 组件ref 属性无效
      this.handleReset();
      this.visible = false;
      this.isEdit = false;
    },
    async handleSearchCustomer(query) {
      this.loadGoodsByWms(query);
    },
    handleNumberBigChange(event) {
      this.formData.bookingNumBig = event.target.value || 0;
    },
    handleNumberSmallChange(event) {
      this.formData.bookingNumSmall = event.target.value || 0;
    },
    addBatch() {
      if (this.isHaveAttrBath) {
        const batchAttributeId = this.formData.goods.qualityControlExt
        && this.formData.goods.qualityControlExt.batchAttributeId;
        this.formData.batchs.push({ id: batchAttributeId, selected: false });
      } else {
        this.formData.batchs.push({ selected: false });
      }
    },
    cutBatch(batchItem, batchInex) {
      // 有可能是一次性添加多行 在进行批属性选择 会造成ID 重复 ，所以使用索引来筛选删除
      if (this.formData.batchs.length > 1) {
        this.formData.batchs = this.formData.batchs.filter((item, index) => batchInex !== index);
      } else {
        this.formData.batchs = [{}];
      }
    },
    async loadGoodsByWms(query) {
      const result = await loadGoodsByWms({}, { shipperId: this.shipperId, searchValue: query });
      this.goods = result.records;
      // this.goods = result.map((item) => ({ ...item, selected: false }));
      if (this.isEdit) {
        const waitQueryGoods = result.records
          .find((item) => (item.goodsCode === this.formData.goods.goodsCode));
        this.formData.goods = waitQueryGoods;
        this.loadRuleBatchAttribute(waitQueryGoods);
      }
    },
    async loadRuleBatchAttribute(goods) {
      // this.formData = goods || { batchs: [{}] };
      if (goods.qualityControlExt && goods.qualityControlExt.batchAttributeId) {
        const result = await ruleBatchAttribute({ id: goods.qualityControlExt.batchAttributeId });
        this.goodBatchs = result.batchAttribute.map((item) => ({ ...item, selected: false }));
      } else {
        this.goodBatchs = [{ selected: false }];
      }
      this.formData.batchs = this.formData.batchs.map((item) => {
        if (!item.batchItemInside) {
          return {
            ...item,
            batchValue: item.batchValue || item.attrValue,
            batchItemInside: this.goodBatchs.find((batch) => batch.fieldCode === item.attrCode),
          };
        }
        return {
          ...item,
        };
      });
    },
    handleChangeBatch(index) {
      this.formData.batchs = this.formData.batchs.map((item, i) => {
        if (i === index) {
          return {
            batchItemInside: item.batchItemInside,
            batchValue: '',
          };
        }
        return item;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.action {
  padding: 15px 0;
}

.number-box {
  width: 120px;
  display: flex;
}

.number-unit {
  width: 30px;
  flex-shrink: 0;
}

.width-100 {
  width: 100%;
}

.margin-right {
  margin-right: 8px;
  width: auto;
}

.margin-bottom {
  margin-bottom: 8px;
  align-items: center;
}

.goods-attr {
  margin: 0 10px;
  display: inline-block;
  min-width: 200px;

  & + .goods-attr {
    margin-left: 10px;
  }

  &:last-child {
    min-width: auto;
  }
}
</style>
<style lang="scss">
.inb-add-number {
  width: 80px;
  flex-shrink: 0;
  margin-right: 8px;

  .el-input {
    width: 80px;
  }
}

.inbound-order-dialog {
  .el-input__inner {
    height: 32px !important;
  }
}
</style>
